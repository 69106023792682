{
  "button": {
    "back": "Retour",
    "cancel": "Annuler",
    "confirm": "Confirmer",
    "fullscreen_enter": "Passer en mode plein écran",
    "fullscreen_exit": "Quitter le mode plein écran",
    "refresh": "Rafraîchir",
    "toggle_dark": "Basculer en mode sombre",
    "toggle_langs": "Changer de langue",
    "remove": "Retirer",
    "clear_all": "Tout effacer",
    "delete": "Effacer",
    "edit": "Éditer",
    "buy_now": "Acheter maintenant",
    "enter_another_card": "Entrez une autre carte",
    "try_again": "Réessayer",
    "enter_another_code": "Entrez un autre code",
    "submit_code": "Soumettre le code",
    "download": "Télécharger",
    "no": "Non",
    "go_to_homepage": "Aller à la page d'accueil",
    "slide_back": "Diapositive précédente",
    "slide_forward": "Diapositive suivante",
    "exit": "Sortie",
    "full": "Complet",
    "regular": "Régulier",
    "rename": "Renommer",
    "get": "Obtenir"
  },
  "common": {
    "loading": "Chargement...",
    "loading_failed": "Échec du chargement",
    "empty_search": "Aucun résultat trouvé avec un titre \"{searchValue}\".",
    "empty_list": "La liste est vide.\nRevenez plus tard.",
    "data_not_found": "Données introuvables ou serveur ne répondant pas. Veuillez réessayer plus tard.",
    "empty_events_list": "Il n'y a pas encore d'événements",
    "get_mobile_app": "Obtenez la meilleure expérience pour \n{appName}"
  },
  "not-found": "Page non trouvée",
  "locales": {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "uk-UA": "Ukrainian",
    "pl": "Polish",
    "pt": "Portuguese",
    "fr": "French",
    "he": "Hebrew",
    "it": "Italian",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni",
    "title": "Choisissez la langue du site",
    "ca": "Catalan"
  },
  "baseHeader": {
    "menu": {
      "account": "Votre compte",
      "language": "Langue : {currentLanguage}",
      "logout": "Se déconnecter",
      "manage_following": "Gérer les suivis",
      "select_language": "Choisir la langue",
      "selected_videos": "Vidéos sélectionnées",
      "my_clips_and_highlights": "Mes extraits/meilleurs moments"
    }
  },
  "labels": {
    "sign_in": "S'identifier",
    "sign_up": "S'inscrire",
    "continue": "Continuer",
    "email": "E-mail",
    "forgot_password": "Mot de passe oublié",
    "password": "Mot de passe",
    "date_added": "Date ajoutée :",
    "highlights": "Surligner | Les mèches",
    "latest": "Dernier",
    "live": "Vivre",
    "password_new": "nouveau mot de passe",
    "password_old": "ancien mot de passe",
    "password_repeat": "Répéter le mot de passe",
    "save": "sauvegarder",
    "see_all": "Tout voir >",
    "upcoming": "A venir",
    "update": "Mise à jour",
    "country": "Pays",
    "phone": "Téléphoner",
    "search": "Chercher",
    "load_more": "Charger plus",
    "follow": "Suivre",
    "following": "Suivant",
    "total": "Total",
    "all": "Tout",
    "sport_type": "Genre sportif",
    "full_name": "Nom et prénom",
    "newest": "Le plus récent",
    "oldest": "Le plus ancien",
    "modal_labels": {
      "delete_clip": "Voulez-vous vraiment supprimer ce clip ?",
      "delete_highlight": "Voulez-vous vraiment supprimer cette surbrillance ?",
      "delete_profile": "Êtes-vous sûr de vouloir supprimer votre profil ?",
      "delete_tag": "Êtes-vous sûr de vouloir supprimer ces balises ? | \nVoulez-vous vraiment supprimer cette balise ?"
    },
    "active": "Actif",
    "submit": "Soumettre",
    "purchase": "Acheter",
    "confirm_new_password": "Confirmer le nouveau mot de passe",
    "enter_new_password": "Entrez un nouveau mot de passe",
    "dark": "Sombre",
    "light": "Lumière",
    "powered_by": "Alimenté par",
    "policy": "Politique de confidentialité",
    "terms": "Conditions d'utilisation",
    "next": "Prochain",
    "search_input_placeholder": "Rechercher des écoles, des clubs, des ligues, etc.",
    "skip": "Sauter",
    "amount": "Montant",
    "date": "Date",
    "type": "Taper",
    "continue_with": "Continuer avec {fournisseur}",
    "or": "ou",
    "anytime": "N'importe quand",
    "event_date": "Date de l'événement",
    "past_30_days": "30 derniers jours",
    "past_7_days": "7 derniers jours",
    "past_90_days": "90 derniers jours",
    "today": "Aujourd'hui",
    "yesterday": "Hier",
    "all_results": "Tous les résultats",
    "events": "Événements",
    "views": "pas de vues |\n{count} vue |\n{count} vues",
    "clip": "Clip |\nClips",
    "tag": "Balise |\nMots clés",
    "game_videos": "Vidéos de jeux",
    "bookmark_add": "Ajouter à la sélection",
    "bookmark_remove": "Supprimer de la sélection",
    "share": "Partager",
    "days": "Journées | \njournée | \njournées",
    "hours": "Heures | \nheure | \nheures",
    "minutes": "Minutes | \nminute | \nminutes",
    "seconds": "Secondes | \nseconde | \nsecondes",
    "latest_events": "Derniers évènements",
    "latest_highlights": "Derniers temps forts",
    "statistics": "Statistiques",
    "commerce_category_labels": {
      "free": "Libre",
      "member": "Membre",
      "ppv": "Payer par vue",
      "subscription": "Abonnement",
      "rental": "De location",
      "premium": "Prime"
    },
    "lock_event_message": {
      "member": "Veuillez vous connecter pour regarder le match",
      "geo_blocking": "Nous sommes désolés, mais la vidéo que vous essayez de lire est limitée à votre région",
      "event_restricted": "Cet événement est limité à votre emplacement",
      "premium": "Abonnez-vous pour regarder",
      "subscription": ""
    },
    "main": "Principal",
    "game_highlights": "Faits saillants du jeu",
    "player_highlights": "Faits saillants des joueurs",
    "watch_full_game": "Voir le jeu complet",
    "your_clips_highlight": "Vos extraits/meilleurs moments",
    "create_highlight": "Créer des faits saillants",
    "access_code": "Entrez le code d'accès",
    "ppv_price": "Regarder à partir de {price}",
    "access_time": "Vous aurez accès à",
    "full_access": "Accès total",
    "limited_access": "Accès limité",
    "ppv_tickets": "Billets PPV",
    "subscriptions": "Abonnements",
    "release_date": "Date de sortie",
    "months": "Mois | \nmois | \nmois",
    "weeks": "Semaines | \nla semaine | \nsemaines",
    "years": "Années | \nan | \nannées",
    "change_product": "Changer de produit",
    "go_to_event": "Aller à l'événement",
    "go_to_account": "Aller au compte",
    "go_to_main_page": "Aller à la page principale",
    "name": "Nom",
    "update_card": "Mettre à jour la carte",
    "page_not_found": "Page non trouvée",
    "inactive": "Inactif",
    "my_club": "Mon Club",
    "select_your_club": "Sélectionnez votre club",
    "add_to_selected": "Ajouter à la sélection",
    "remove_from_selected": "Supprimer de la sélection",
    "password_not_match": "Les mots de passe ne correspondent pas",
    "search_by_sport_type": "Recherche par type de sport",
    "videos": "Vidéos",
    "category": "Catégorie",
    "reset_password": "Veuillez entrer votre nouveau mot de passe",
    "min_8_characters": "8 caractères minimum",
    "league": "Ligue",
    "here": "Ici",
    "suggested": "Suggéré",
    "enter_old_password": "Entrez l'ancien mot de passe",
    "less": "Moins",
    "more": "Plus",
    "durationRenew": "Durée : sera renouvelée {duration} {units} après l'achat | \nDurée : sera renouvelée le {duration}",
    "duration": "Durée : expire {duration} {units} après l'achat | \nDurée : expire {duration}",
    "team": "Équipe",
    "team_1_logo": "Logo de l'équipe 1",
    "team_2_logo": "Logo de l'équipe 2",
    "stats": "Statistiques",
    "gameType_labels": {
      "game": "Jeu",
      "other": "Autre",
      "practice": "Pratique",
      "training": "entraînement"
    },
    "fullName": "Nom et prénom",
    "archive": "Archive",
    "panoramic_mode": "Vous êtes en mode panoramique",
    "postal_code": "Code Postal",
    "search_event_league_team": "Rechercher un événement, une ligue ou une équipe",
    "back_to_site": "Retour au site",
    "done": "Fait",
    "search_teams": "Équipes de recherche",
    "game_recap": "Récapitulatif du jeu",
    "play_by_play": "Jeu par jeu",
    "your_clips": "Vos clips",
    "gender": "Genre",
    "gender_labels": {
      "female": "Femelle",
      "male": "Mâle",
      "mixed": "Mixte"
    }
  },
  "pages": {
    "auth_sign_up": {
      "enter_your_details_below": "Entrez vos coordonnées ci-dessous",
      "password_updated_successuly_text": "Votre mot de passe a été mis à jour avec succès",
      "already_have_account": "Vous avez déjà un compte?",
      "follow_category_header": "Sélectionnez les catégories que vous souhaitez suivre",
      "back_to_all_teams": "Retour à toutes les équipes",
      "follow_teams_header": "Sélectionnez les équipes que vous souhaitez suivre",
      "back_to_all_categories": "Retour à toutes les catégories"
    },
    "account": {
      "account_delete_button": "Supprimer le compte",
      "tab_billing_title": "Détails de facturation",
      "tab_devices_title": "Dispositifs",
      "tab_general_title": "Général",
      "tab_password_title": "Mot de passe",
      "tab_subscription_title": "Abonnement",
      "account_delete_confirm": "Êtes-vous sûr de vouloir supprimer votre profil ?",
      "account_delete_description": "Cette action est irréversible.",
      "tab_ppv_access_text": "Le billet PPV donne accès à",
      "tab_ppv_no_ppv": "Vous n'avez pas de billets PPV. \nVeuillez vous rendre sur la page de l'événement pour acheter un billet.",
      "tab_ppv_title": "Billets PPV",
      "account_delete_successfully": "Votre compte a été supprimé avec succès !",
      "account_update_successfully": "Votre compte a été mis à jour avec succès !"
    },
    "auth_password": {
      "forgot_password_help_text": "Nous vous aiderons à le réinitialiser et à vous remettre sur la bonne voie.",
      "back_to_sign_in": "Retour à la connexion",
      "send_reset_link": "Envoyer le lien de réinitialisation",
      "set_new_password": "Definir un nouveau mot de passe",
      "forgot_password_success_text": "Un e-mail contenant des instructions pour réinitialiser votre mot de passe a été envoyé à {email}. \nSi vous ne recevez pas l'e-mail dans quelques minutes, cliquez sur le bouton Renvoyer les instructions ci-dessous.",
      "resend_instruction": "Renvoyer les instructions"
    },
    "clubs": {
      "manage_following": "Gérer les suivis",
      "choose_your_club": "Choisissez votre club",
      "choose_your_club_message": "Choisissez le club auquel vous serez associé"
    },
    "auth_login": {
      "device_no_longer_allowed_error": "Votre appareil actuel ne figure plus dans la liste des appareils autorisés. Veuillez vous reconnecter."
    },
    "auth_login_limit": {
      "device_limit_reached": "Limite d'appareils atteinte",
      "remove_device_to_login": "Veuillez en supprimer un pour vous connecter",
      "current_device": "Appareil actuel"
    },
    "account_billing": {
      "card_cvv": "CVC / CVV",
      "card_expiry_date": "Date d'expiration",
      "card_holder_name": "Nom sur la carte",
      "card_number": "Numéro de carte",
      "button_back_to_billing_info": "Retour aux informations de facturation",
      "payment_history_empty_text": "Votre historique de paiement est vide",
      "payment_history_title": "Historique de paiement",
      "alert_credit_card_save_success": "Les informations de votre carte de crédit ont été enregistrées avec succès",
      "alert_credit_card_update_success": "Les informations de votre carte de crédit ont été mises à jour avec succès",
      "label_modal_confirm_credit_card_delete": "Une fois votre carte de crédit supprimée, votre abonnement sera également annulé. \nLe contenu est toujours disponible pour vous jusqu'à la date de fin du plan. \nVoulez-vous vraiment supprimer la carte de crédit ?",
      "alert_credit_card_delete_success": "Votre carte de facturation a été supprimée avec succès",
      "payment_in_progress": "Paiement en cours",
      "payment_in_progress_message": "Veuillez ne pas fermer cette page tant que votre paiement n'est pas traité."
    },
    "account_subscription": {
      "active_subscription": "Abonnement actif",
      "button_subscribe_now": "Abonnez-vous maintenant",
      "button_subscription_cancel": "Annuler l'abonnement",
      "subscription_due_date": "Date d'échéance",
      "subscription_expiration_date": "Expiré par",
      "subscription_last_payment_date": "Date du dernier paiement",
      "subscription_next_charge_date": "Prochaine charge",
      "button_back_to_my_subscription": "Retour à mon abonnement",
      "or_select_plan": "ou sélectionnez votre formule",
      "redeem_access_code_label": "Échangez votre code d'accès ici",
      "alert_access_code_redeem_not_found": "Le code d'accès demandé n'existe pas",
      "alert_access_code_redeemed_success": "Code d'accès utilisé avec succès",
      "alert_subscription_cancel_success": "Abonnement annulé avec succès",
      "label_plan_amount": "Montant du régime",
      "label_taxes": "Impôts",
      "label_total_billed": "Total facturé",
      "tab_all_subscriptions": "Tous les abonnements",
      "tab_my_subscriptions": "Mes abonnements",
      "alert_access_code_redeem_code_used": "Code d'accès demandé déjà utilisé",
      "label_full_access": "Accès total",
      "label_full_access_description": "Accès complet à tout le contenu du site Web",
      "label_limited_access": "Accès limité",
      "label_limited_access_description": "Accès limité à tout le contenu du site Web",
      "subscription_expires": "L'abonnement expire le {date}",
      "payment_details": "{title} : détails du paiement",
      "no_subscriptions_text": "Il n'y a pas d'abonnements actifs dans la liste.\n\nRevenez plus tard",
      "redeem_access_code_title": "Échangez votre code d'accès",
      "button_back_to_my_tickets": "Retour à mes billets",
      "list_subscriptions_empty": "La liste des abonnements disponibles est vide. \nRevenez plus tard",
      "button_subscription_keep": "Conserver l'abonnement",
      "cancel_subscription_modal_subtitle": "Votre abonnement sera annulé à la fin de votre période de facturation le {date}. \nVous pouvez changer d'avis à tout moment avant cette date.",
      "cancel_subscription_modal_title": "Êtes-vous sûr de vouloir résilier votre abonnement ?",
      "checkbox_apply_as_new_credit_card": "Enregistrer les détails de la carte",
      "renew_subscription_expires": "L'abonnement est récurrent. \nProchaine date de facturation {date}"
    },
    "videos": {
      "last_viewed": "Vu pour la dernière fois",
      "user_interesting": "Vous pourriez être intéressé",
      "description_no_items": "C'est l'endroit pour vos vidéos préférées. \nEnregistrez, examinez et partagez !",
      "title_no_items": "Ajouter des vidéos à la sélection"
    },
    "clips": {
      "title_no_clips": "Vous n'avez pas encore de clips. \nAccédez à la vidéo et créez votre premier clip !",
      "title_no_highlights": "Vous n'avez pas encore de faits saillants.\nAccédez à la vidéo et créez vos premiers temps forts !",
      "title_no_items_with_selected_filter": "Aucun élément trouvé avec ce filtre"
    },
    "auth_sign_in": {
      "welcome_message": "Content de te revoir",
      "new_to": "Nouveau à",
      "auth_terms": "En continuant, vous acceptez nos {terms_of_service} et {privacy_policy}",
      "sign_in_with_provider": "Veuillez vous connecter avec vos identifiants {provider}"
    },
    "account_password": {
      "alert_password_update_success": "Votre mot de passe a été changé avec succès"
    },
    "league": {
      "all_rounds": "Tous les tours"
    },
    "events": {
      "event_start_date": "L'événement commencera le {date}",
      "failed_statistics": "Échec du chargement des statistiques liées à cet événement. Veuillez réessayer ultérieurement.",
      "modal_share_with_time": "Commencer à {time}",
      "modal_share_title": "Partage cette vidéo",
      "tabs": {
        "event_tags": {
          "create_tag": "Créer une balise",
          "title_no_tags_not_logged_in": "Il n'y a pas encore de tags dans la liste, veuillez vous connecter afin de créer de nouveaux tags",
          "choose_player_color": "Entrez un joueur '",
          "choose_tag": "Choisissez le type de balise",
          "make_tag_global": "Rendre la balise globale",
          "name_the_tag": "Nommez la balise",
          "tag_creating_successfully_text": "La balise a été créée avec succès",
          "title_enabled_tagging": "Il n'y a pas de balises dans la liste",
          "update_tag": "Mettre à jour la balise",
          "tag_updating_successfully_text": "La balise a été mise à jour avec succès",
          "filters": {
            "by_player_number": "Joueur '",
            "by_type": "Par type",
            "clear_all_btn": "Effacer tout |\nEffacer tout ({count}) |\nEffacer tout ({count})",
            "no_tags": "Aucun tag correspondant au filtre sélectionné",
            "popup_title": "Filtre",
            "search_input_placeholder": "Rechercher des balises spécifiques",
            "show_results_btn": "Montrer les résultats",
            "select_all": "Tout sélectionner | \nTout sélectionner ({count}) | \nTout sélectionner ({count})",
            "by_team": "Par équipe"
          },
          "tag_deleting_successfully_text": "La balise a été supprimée avec succès",
          "is_external_content": "Ceci est un contenu externe, vous ne pouvez pas créer de tag",
          "title_no_tags": "Aucun tag n'a encore été créé",
          "pause_all_btn": "Tout mettre en pause",
          "play_all_btn": "Jouer à tous",
          "choose_team": "Choisissez une équipe"
        },
        "event_videos": {
          "create_clip": "Créer un clip",
          "title_no_clips_not_logged_in": "Il n'y a pas encore de clips dans la liste, veuillez vous connecter afin de créer de nouveaux clips",
          "name_the_clip": "Nommez le clip",
          "clip_creating_successfully_text": "Votre clip sera bientôt prêt",
          "title_enabled_clipping": "Il n'y a pas de clips dans la liste",
          "title_no_clips": "Aucun clip n'a encore été créé",
          "clip_deleted_successfully_text": "Le clip a été supprimé avec succès",
          "rename_clip": "Renommer le clip",
          "clip_updating_successfully_text": "Le clip a été mis à jour avec succès"
        },
        "event_stats": {
          "empty_stats_data": "Les données statistiques sont vides",
          "game_board_title": "Statistiques du jeu",
          "score_board_title": "Score"
        }
      },
      "viewing_conditions": "Choisissez vos conditions de visionnage",
      "appreciation": "Merci!",
      "success_purchased": "Vous avez acheté avec succès {subscription}",
      "failed_purchased_message": "Veuillez réessayer ou entrer une autre carte.",
      "purchased_declined": "Le paiement a été refusé",
      "watching_event_by_text": "Vous regardez cet événement par",
      "failed_code_applied_message": "Veuillez réessayer ou entrer un autre code.",
      "failed_code_applied_title": "Code invalide",
      "success_code_applied": "{title} code appliqué avec succès",
      "submit_reedem_code_input_placeholder": "Entrez votre code d'accès ici",
      "failed_already_used_code": "Ce code a déjà été utilisé",
      "event_will_start_in_a_few_min": "L'événement commencera automatiquement dans quelques minutes...",
      "player_errors": {},
      "game_video_login_message": "Veuillez vous connecter pour voir les vidéos du jeu",
      "tags_login_message": "Veuillez vous connecter pour voir les balises",
      "success_purchased_secondary": "Votre achat a réussi",
      "time_till_kick_off": "Heure jusqu'au coup d'envoi :",
      "failed_purchased_message_2": "Veuillez réessayer ou utiliser un autre mode de paiement."
    },
    "club": {
      "favorite_club_creating_successfully_text": "Votre club a été choisi avec succès"
    },
    "categories": {
      "no_following_category_for_logged_user": "Vous ne suivez rien pour le moment. \nCliquez {here} pour rester à jour avec vos événements préférés",
      "no_following_category_for_unlogged_user": "Veuillez vous {login} pour rester informé de vos événements préférés"
    }
  },
  "sport_types": {
    "americanFootball": "Football américain",
    "beachvolleyball": "Beach-volley",
    "fieldhockey": "Hockey sur gazon",
    "icehockey": "Hockey sur glace",
    "rollerhockey": "Roller-hockey",
    "badminton": "Badminton",
    "baseball": "Base-ball",
    "basketball": "Basket",
    "bowling": "Bowling",
    "cricket": "Criquet",
    "floorball": "Floorball",
    "futsal": "Futsal",
    "gymnastics": "Gymnastique",
    "handball": "Handball",
    "lacrosse": "Lacrosse",
    "netball": "Netball",
    "other": "Autre",
    "rugby": "Le rugby",
    "snooker": "Billard",
    "soccer": "Football",
    "softball": "Balle-molle",
    "swimming": "Natation",
    "tennis": "Tennis",
    "volleyball": "Volley-ball",
    "waterpolo": "Water-polo",
    "wrestling": "Lutte",
    "football": "Football"
  },
  "baseFooter": {
    "rights": "Tous les droits sont réservés"
  },
  "footer_links": {
    "about_us": "À propos de",
    "faq": "FAQ",
    "privacy_policy": "Intimité",
    "terms_of_service": "Conditions",
    "custom": "{custom}"
  },
  "countries": {
    "AD": "Andorre",
    "AE": "Emirats Arabes Unis",
    "AF": "Afghanistan",
    "AG": "Antigua-et-Barbuda",
    "AI": "Anguilla",
    "AL": "Albanie",
    "AM": "Arménie",
    "AO": "Angola",
    "AR": "Argentine",
    "AS": "Samoa américaines",
    "AT": "L'Autriche",
    "AU": "Australie",
    "AW": "Aruba",
    "AZ": "Azerbaïdjan",
    "BA": "Bosnie Herzégovine",
    "BB": "Barbade",
    "BD": "Bengladesh",
    "BE": "Belgique",
    "BF": "Burkina Faso",
    "BG": "Bulgarie",
    "BH": "Bahreïn",
    "BI": "Burundi",
    "BJ": "Bénin",
    "BM": "Bermudes",
    "BO": "Bolivie",
    "BR": "Brésil",
    "BS": "Bahamas",
    "BT": "Bhoutan",
    "BW": "Bostwana",
    "BY": "Biélorussie",
    "BZ": "Bélize",
    "CA": "Canada",
    "CF": "République centrafricaine",
    "CG": "Congo",
    "CH": "Suisse",
    "CK": "les Îles Cook",
    "CL": "Chili",
    "CM": "Cameroun",
    "CN": "Chine",
    "CO": "Colombie",
    "CR": "Costa Rica",
    "CU": "Cuba",
    "CX": "L'île de noël",
    "CY": "Chypre",
    "DE": "Allemagne",
    "DJ": "Djibouti",
    "DK": "Danemark",
    "DM": "Dominique",
    "DO": "République Dominicaine",
    "DZ": "Algérie",
    "EC": "Equateur",
    "EE": "Estonie",
    "EG": "Egypte",
    "ER": "Érythrée",
    "ES": "Espagne",
    "ET": "Ethiopie",
    "FI": "Finlande",
    "FJ": "Fidji",
    "FK": "les îles Falkland",
    "FM": "Micronésie",
    "FO": "Îles Féroé",
    "FR": "France",
    "GA": "Gabon",
    "GD": "Grenade",
    "GE": "Géorgie",
    "GF": "Guyane Française",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GL": "Groenland",
    "GM": "Gambie",
    "GN": "Guinée",
    "GP": "Guadeloupe",
    "GQ": "Guinée Équatoriale",
    "GR": "Grèce",
    "GS": "Géorgie du Sud et îles Sandwich du Sud",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Guinée-Bissau",
    "GY": "Guyane",
    "HN": "Honduras",
    "HR": "Croatie",
    "HT": "Haïti",
    "HU": "Hongrie",
    "ID": "Indonésie",
    "IE": "Irlande",
    "IL": "Israël",
    "IN": "Inde",
    "IO": "Territoire britannique de l'océan Indien",
    "IQ": "Irak",
    "IR": "L'Iran",
    "IS": "Islande",
    "IT": "Italie",
    "JM": "Jamaïque",
    "JO": "Jordan",
    "JP": "Japon",
    "KE": "Kenya",
    "KG": "Kirghizistan",
    "KH": "Cambodge",
    "KI": "Kiribati",
    "KM": "Comores",
    "KW": "Koweit",
    "KY": "Îles Caïmans",
    "KZ": "Kazakhstan",
    "LB": "Liban",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Libéria",
    "LS": "Lesotho",
    "LT": "Lituanie",
    "LU": "Luxembourg",
    "LV": "Lettonie",
    "LY": "Libye",
    "MA": "Maroc",
    "MC": "Monaco",
    "MD": "Moldavie",
    "ME": "Monténégro",
    "MG": "Madagascar",
    "MH": "Iles Marshall",
    "ML": "Mali",
    "MM": "Birmanie",
    "MN": "Mongolie",
    "MP": "Îles Mariannes du Nord",
    "MQ": "Martinique",
    "MR": "Mauritanie",
    "MS": "Montserrat",
    "MT": "Malte",
    "MU": "Maurice",
    "MV": "Maldives",
    "MW": "Malawi",
    "MX": "Mexique",
    "MY": "Malaisie",
    "NA": "Namibie",
    "NC": "Nouvelle Calédonie",
    "NE": "Niger",
    "NF": "l'ile de Norfolk",
    "NG": "Nigeria",
    "NI": "Nicaragua",
    "NL": "Pays-Bas",
    "NO": "Norvège",
    "NP": "Népal",
    "NR": "Nauru",
    "NU": "Niué",
    "NZ": "Nouvelle-Zélande",
    "OM": "Oman",
    "PA": "Panama",
    "PE": "Pérou",
    "PF": "Polynésie française",
    "PG": "Papouasie Nouvelle Guinée",
    "PH": "Philippines",
    "PK": "Pakistan",
    "PL": "Pologne",
    "PR": "Porto Rico",
    "PT": "le Portugal",
    "PW": "Palaos",
    "PY": "Paraguay",
    "QA": "Qatar",
    "RO": "Roumanie",
    "RS": "Serbie",
    "RW": "Rwanda",
    "SA": "Arabie Saoudite",
    "SB": "îles Salomon",
    "SC": "les Seychelles",
    "SD": "Soudan",
    "SE": "Suède",
    "SG": "Singapour",
    "SI": "Slovénie",
    "SK": "Slovaquie",
    "SL": "Sierra Leone",
    "SM": "Saint Marin",
    "SN": "Sénégal",
    "SV": "Le Salvador",
    "TC": "îles Turques-et-Caïques",
    "TD": "Tchad",
    "TG": "Aller",
    "TH": "Thaïlande",
    "TJ": "Tadjikistan",
    "TK": "Tokélaou",
    "TM": "Turkménistan",
    "TN": "Tunisie",
    "TO": "Tonga",
    "TR": "Turquie",
    "TT": "Trinité-et-Tobago",
    "TV": "Tuvalu",
    "TW": "Taïwan",
    "UA": "Ukraine",
    "UG": "Ouganda",
    "UY": "Uruguay",
    "UZ": "Ouzbékistan",
    "VE": "Venezuela",
    "VU": "Vanuatu",
    "WF": "Wallis et Futuna",
    "WS": "Samoa",
    "YE": "Yémen",
    "YT": "Mayotte",
    "ZA": "Afrique du Sud",
    "ZM": "Zambie",
    "ZW": "Zimbabwe",
    "BN": "Brunéi",
    "CV": "Cap-Vert",
    "CW": "Curacao",
    "HK": "Hong Kong RAS Chine",
    "KP": "Corée du Nord",
    "KR": "Corée du Sud",
    "LA": "Laos",
    "MO": "Macao RAS Chine",
    "PS": "Territoire Palestinien",
    "RE": "Réunion",
    "RU": "Russie",
    "SY": "Syrie",
    "TZ": "Tanzanie",
    "GB": "Royaume-Uni",
    "US": "États-Unis",
    "VG": "Îles Vierges britanniques",
    "VI": "Îles Vierges américaines",
    "VN": "Viêt Nam",
    "CD": "Congo, Dém.\nRép. du (Zaïre)",
    "AX": "Iles Aland",
    "BQ": "Pays-Bas caribéens",
    "EA": "Ceuta et Melilla",
    "EH": "Sahara occidental",
    "GG": "Guernesey",
    "IC": "les îles Canaries",
    "IM": "île de Man",
    "JE": "Jersey",
    "MZ": "Mozambique",
    "PN": "Îles Pitcairn",
    "SO": "Somalie",
    "SS": "Soudan du sud",
    "SX": "Saint-Martin",
    "TA": "Tristan de Cunha",
    "TF": "Terres australes françaises",
    "VA": "Cité du Vatican",
    "XK": "Kosovo",
    "AC": "Île de l'Ascension",
    "CZ": "Tchéquie",
    "CC": "Îles Cocos (Keeling)",
    "TL": "Timor oriental",
    "CI": "Côte d'Ivoire",
    "MK": "Macédoine du Nord",
    "SR": "Suriname",
    "SZ": "Eswatini",
    "DG": "Diego García"
  },
  "errors": {
    "expired_session_error": "Votre session a expiré. \nVeuillez vous reconnecter.",
    "vod_files_not_found": "Oops! \nFichiers vidéo introuvables",
    "vod_playback_failed": "Oops! \nLa lecture a échoué pendant le flux VOD",
    "could_not_find_event": "Désolé, nous n'avons pas trouvé l'événement que vous recherchez.",
    "could_not_find_page": "Désolé, nous n'avons pas trouvé la page que vous recherchez.",
    "could_not_find_team": "Désolé, nous n'avons pas trouvé l'équipe que vous recherchez.",
    "event_not_found": "Evénement introuvable",
    "page_not_found": "Page non trouvée",
    "team_not_found": "Equipe introuvable",
    "could_not_find_results": "Désolé, nous n'avons trouvé aucun résultat pour cette recherche.",
    "ERR400CL11": "Échec de la création du clip",
    "ERR400CM11": "Paiement échoué",
    "ERR400CM12": "Échec de la commande",
    "ERR400CM13": "Capturer le paiement a échoué",
    "ERR400CM14": "Échec de l'enregistrement des informations du marchand sur l'abonnement",
    "ERR400CM15": "Veuillez vérifier les informations de votre carte de crédit",
    "ERR400CM16": "Valeur de plate-forme fournie incorrecte",
    "ERR400GN00": "Mauvaise demande",
    "ERR400GN11": "ID non valide fourni",
    "ERR400GN12": "Champs non modifiables fournis",
    "ERR400ID11": "Identité non valide fournie",
    "ERR400ID12": "Pièce d'identité fournie invalide",
    "ERR400ID13": "L'identité ne peut pas être ajoutée à la liste des clubs",
    "ERR400ID14": "Paire d'identité et d'élément d'identité fourni invalide",
    "ERR400TG11": "Échec de la création de la balise",
    "ERR400UR11": "Le prénom et le nom du profil sont obligatoires",
    "ERR400UR12": "L'e-mail est requis",
    "ERR400UR13": "L'utilisateur a déjà une carte de crédit enregistrée",
    "ERR400UR14": "Veuillez remplir les champs prénom, nom et e-mail de votre profil avant d'enregistrer une carte de crédit",
    "ERR400UR17": "L'utilisateur n'a pas de carte de crédit à modifier",
    "ERR400UR18": "L'abonnement est déjà annulé",
    "ERR400UR19": "L'abonnement n'est pas actif",
    "ERR400UR20": "Le nom d'utilisateur et le mot de passe sont requis",
    "ERR400UR21": "Le compte n'est pas actif",
    "ERR401AT11": "Le jeton est requis",
    "ERR401AT12": "Jeton de réinitialisation de mot de passe invalide",
    "ERR401AT13": "Jeton d'actualisation non valide",
    "ERR401AT14": "Émetteur sujet inconnu",
    "ERR401AT15": "Type de jeton d'objet non pris en charge",
    "ERR401AT16": "Jeton invalide",
    "ERR401AT17": "Jeton expiré",
    "ERR401AT18": "Impossible d'obtenir un e-mail du jeton",
    "ERR401AT19": "Utilisateur non trouvé",
    "ERR401GN00": "Non autorisé",
    "ERR401UR11": "Le nom d'utilisateur ou le mot de passe est erroné. \nVeuillez vérifier vos informations d'identification.",
    "ERR402GN00": "Paiement Requis",
    "ERR403GN00": "Interdit",
    "ERR403TG11": "L'utilisateur ne peut pas mettre à jour les balises globales",
    "ERR403TG12": "L'utilisateur ne peut pas créer de balises globales",
    "ERR403TG13": "L'utilisateur ne peut pas supprimer les tags qui n'ont pas été créés par un",
    "ERR403TG14": "L'utilisateur ne peut pas supprimer les balises globales",
    "ERR404CM11": "Impossible d'obtenir des abonnements",
    "ERR404CM12": "Code d'accès introuvable",
    "ERR404EV11": "Impossible d'obtenir des informations sur l'événement",
    "ERR404EV12": "Événement ou VOD introuvable",
    "ERR404GN00": "Pas trouvé",
    "ERR404ID00": "Identité introuvable",
    "ERR404ID11": "L'identité n'a pas pu être récupérée",
    "ERR404PR00": "Produit non trouvé",
    "ERR404PR11": "Impossible d'obtenir des informations ppv",
    "ERR404TG00": "Balise introuvable",
    "ERR404TM00": "Equipe introuvable",
    "ERR404UR00": "Utilisateur non trouvé",
    "ERR404UR11": "Commande introuvable",
    "ERR404UR12": "Abonnement introuvable",
    "ERR404UR13": "L'utilisateur n'existe pas",
    "ERR404UR14": "Le profil n'existe pas",
    "ERR404UR15": "La carte n'a pas été trouvée pour cet utilisateur",
    "ERR404UR16": "Vidéo introuvable dans la liste de surveillance",
    "ERR404VD11": "La vidéo n'a pas pu être récupérée",
    "ERR409CM11": "Code d'accès déjà utilisé",
    "ERR409GN00": "Conflit",
    "ERR409PR12": "Produit déjà acheté",
    "ERR409UR11": "Le club est déjà ajouté",
    "ERR409UR12": "Utilisateur déjà enregistré",
    "ERR422GN00": "Contenu non traitable",
    "ERR451GN00": "Indisponible pour des raisons légales",
    "ERR500GN00": "Erreur interne du serveur",
    "ERR500NT12": "Échec de l'envoi de l'e-mail de réinitialisation du mot de passe",
    "ERR501GN00": "Pas mis en œuvre",
    "ERR502GN00": "Mauvaise passerelle",
    "ERR503GN00": "service non disponible",
    "ERR504GN00": "Délai d'expiration de la passerelle",
    "could_not_find_league": "Désolé, nous n'avons pas trouvé la ligue que vous recherchez.",
    "default_error_message": "Une erreur de serveur s'est produite. \nVeuillez réessayer plus tard",
    "device_limit_exceeded": "Limite d'appareils dépassée !",
    "league_not_found": "Ligue introuvable",
    "vod_corrupted_files": "La vidéo n'a pas pu être chargée",
    "passwords_dont_match": "Les mots de passe ne correspondent pas",
    "incomplete_cvc": "Le code de sécurité de votre carte est incomplet !",
    "incomplete_expiry": "La date d'expiration de votre carte est incomplète !",
    "incomplete_number": "Votre numéro de carte est incomplet !",
    "invalid_expiry_month_past": "L'année d'expiration de votre carte est passée !",
    "invalid_expiry_year": "L'année d'expiration de votre carte n'est pas valide !",
    "invalid_expiry_year_past": "L'année d'expiration de votre carte est passée !",
    "invalid_number": "Votre numéro de carte est invalide !",
    "popup_closed": "La popup a été fermée",
    "login_failed": "La connexion a échoué",
    "ERR400UR24": "L'ancien mot de passe est erroné. \nVeuillez vérifier vos informations d'identification",
    "live_stream_error_message": "Oups ! \nOn dirait qu'il y a un peu de retard. \nNotre équipe travaille activement pour résoudre le problème le plus rapidement possible. \nMerci pour votre patience !"
  },
  "tags": {
    "baseball_bottom_1st": "bas 1er",
    "baseball_bottom_2nd": "bas 2e",
    "baseball_double": "double",
    "baseball_double_play": "double jeu",
    "baseball_fly_out": "envoler",
    "baseball_ground_out": "au sol",
    "baseball_hit": "frapper",
    "baseball_home_run": "Home Run",
    "baseball_popout": "pop-up",
    "baseball_run": "courir",
    "baseball_single": "seul",
    "baseball_stolen_base": "base volée",
    "baseball_strikeout": "barré",
    "baseball_substitution": "substitution",
    "baseball_top_1st": "premier 1er",
    "baseball_top_2nd": "premier 2e",
    "baseball_triple_play": "triple jeu",
    "baseball_tripple": "triplé",
    "baseball_walk": "marcher",
    "basketball_alley_oop": "ruelle oups",
    "basketball_assist": "aider",
    "basketball_big_play": "grand jeu",
    "basketball_block": "bloc",
    "basketball_dunk": "tremper",
    "basketball_momentym": "élan",
    "basketball_money_time": "temps d'argent",
    "basketball_substitution": "substitution",
    "basketball_three_point": "trois points",
    "beach_volleyball_dig": "creuser",
    "beach_volleyball_double": "double",
    "beach_volleyball_free_ball": "ballon gratuit",
    "beach_volleyball_match_point": "balle de match",
    "beach_volleyball_mishit": "coup manqué",
    "beach_volleyball_serve": "servir",
    "beach_volleyball_set_point": "consigne",
    "beach_volleyball_shank": "jarret",
    "beach_volleyball_side_out": "dehors",
    "beach_volleyball_spike": "pic",
    "beach_volleyball_substitution": "substitution",
    "field_ball_loss": "perte de balle",
    "field_ball_possesion": "possession de balle",
    "field_circle_penetration": "pénétration du cercle",
    "field_hockey_4v4": "4v4",
    "field_hockey_big_hit": "grand succès",
    "field_hockey_fight": "lutte",
    "field_hockey_goal": "but",
    "field_hockey_other": "autre",
    "field_hockey_power_play": "jeu de puissance",
    "field_hockey_save": "sauvegarder",
    "field_hockey_shot": "tir",
    "field_hockey_substitution": "substitution",
    "field_interception": "interception",
    "field_penalty_corner": "coin de pénalité",
    "field_press_fp_hc": "presse",
    "field_time_penalty": "pénalité de temps",
    "field_turn_over": "chiffre d'affaires",
    "football_big_catch": "grosse prise",
    "football_big_hit": "grand succès",
    "football_big_run": "grande course",
    "football_field_goal": "but sur le terrain",
    "football_kick_return": "retour de coup de pied",
    "football_kickoff": "démarrer",
    "football_punt_return": "retour de botté",
    "football_substitution": "substitution",
    "football_touch_down": "atterrir",
    "futsal_corner_kick": "coup de pied de coin",
    "futsal_foul": "faute",
    "futsal_free_kick": "coup franc",
    "futsal_goal": "but",
    "futsal_header": "entête",
    "futsal_penalty": "peine",
    "futsal_redcard": "carte rouge",
    "futsal_save": "sauvegarder",
    "futsal_shot": "tir",
    "futsal_shot_on_target": "tir cadré",
    "futsal_substitution": "substitution",
    "futsal_yellowcard": "carte jaune",
    "general_bad": "mauvais",
    "general_drill": "percer",
    "general_good": "bien",
    "gymnastic_wow": "Ouah",
    "handball_foul": "faute",
    "handball_goal": "but",
    "handball_penalty_shot": "tir de pénalité",
    "handball_red_card": "carte rouge",
    "handball_save": "sauvegarder",
    "handball_shot_on_goal": "tir au but",
    "handball_substitution": "substitution",
    "handball_suspension": "suspension",
    "handball_yellow_card": "carte jaune",
    "hockey_4v4": "4v4",
    "hockey_big_hit": "grand succès",
    "hockey_fight": "lutte",
    "hockey_goal": "but",
    "hockey_power_play": "jeu de puissance",
    "hockey_save": "sauvegarder",
    "hockey_shot": "tir",
    "hockey_substitution": "substitution",
    "lacrosse_clear": "clair",
    "lacrosse_face_off": "affronter",
    "lacrosse_goal": "but",
    "lacrosse_ground_ball": "balle au sol",
    "lacrosse_man_up": "homme debout",
    "lacrosse_penalty": "peine",
    "lacrosse_save": "sauvegarder",
    "lacrosse_shot": "tir",
    "lacrosse_substitution": "substitution",
    "other_wow": "autre wow",
    "roller_ball_loss": "perte de balle",
    "roller_ball_possesion": "possession de balle",
    "roller_circle_penetration": "pénétration du cercle",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_big_hit": "grand succès",
    "roller_hockey_fight": "lutte",
    "roller_hockey_goal": "but",
    "roller_hockey_other": "autre",
    "roller_hockey_power_play": "jeu de puissance",
    "roller_hockey_save": "sauvegarder",
    "roller_hockey_shot": "tir",
    "roller_hockey_substitution": "substitution",
    "roller_interception": "interception",
    "roller_penalty_corner": "coin",
    "roller_press_fp_hc": "presse",
    "roller_time_penalty": "pénalité de temps",
    "roller_turn_over": "chiffre d'affaires",
    "rugby_break_down": "panne",
    "rugby_conversion": "conversion",
    "rugby_drop_goal": "objectif de chute",
    "rugby_garry_owen": "Garry Owen",
    "rugby_grubber": "fouilleur",
    "rugby_knock_on": "cogner sur",
    "rugby_maul": "malmener",
    "rugby_penalty_try": "essai de pénalité",
    "rugby_substitution": "substitution",
    "soccer_corner_kick": "coup de pied de coin",
    "soccer_foul": "faute",
    "soccer_free_kick": "coup franc",
    "soccer_goal": "but",
    "soccer_header": "entête",
    "soccer_penalty": "peine",
    "soccer_redcard": "carte rouge",
    "soccer_save": "sauvegarder",
    "soccer_shot": "tir",
    "soccer_shot_on_target": "tir cadré",
    "soccer_substitution": "substitution",
    "soccer_yellowcard": "carte jaune",
    "volleyball_dig": "creuser",
    "volleyball_double": "double",
    "volleyball_free_ball": "ballon gratuit",
    "volleyball_match_point": "balle de match",
    "volleyball_mishit": "coup manqué",
    "volleyball_serve": "servir",
    "volleyball_set_point": "consigne",
    "volleyball_shank": "jarret",
    "volleyball_side_out": "dehors",
    "volleyball_spike": "pic",
    "volleyball_substitution": "substitution",
    "wrestling_wow": "Ouah"
  }
}
